import { FontSizes, getTheme, Icon, Stack, Text, Link } from "@fluentui/react";
import { Trans } from "react-i18next";

function ErrorBoundaryDisplay({ error }: { error: any }) {
  const theme = getTheme();

  const contentStyles: any = {
    root: [
      {
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      },
    ],
  };
  const iconStyles: any = {
    root: [
      {
        fontSize: FontSizes.mega,
        color: theme.palette.neutralTertiaryAlt,
      },
    ],
  };
  const textStyles: any = {
    root: [
      {
        fontSize: FontSizes.xLarge,
        color: theme.palette.neutralSecondary,
      },
    ],
  };

  return (
    <Stack styles={contentStyles}>
      <Icon data-test="icon" iconName="Sad" styles={iconStyles}></Icon>
      <h1>
        <Text data-test="text" style={textStyles}>
          <Trans i18nKey="error.server-error">
            Something went wrong. Try again or contact Trinity Support
            <Link data-test="link" href="mailto:EVDsupport@microsoft.com">
              Support team
            </Link>
            for further assistance.
          </Trans>
        </Text>
      </h1>
      {typeof error.message === "string" ? (
        <h3>
          <Text>{error.message}</Text>
        </h3>
      ) : null}
    </Stack>
  );
}

export default ErrorBoundaryDisplay;
