import "./styles/Icons";
import "./configs/i18n";
import "./App.css";
import { Provider } from "react-redux";
import { store } from "./store/store";
import RoutesComponent from "./routes/RoutesComponent";
import AuthenticationWrapper from "./components/auth/AuthenticationWrapper";
import { MsalProvider } from "@azure/msal-react";
import { authInstance } from "./components/auth/AuthModule";
import Clarity from "@microsoft/clarity";
import { configs } from "./configs";
import ErrorBoundary from "./components/error/ErrorBoundary";

// Make sure to add your actual project id instead of "yourProjectId".
const projectId = configs.client.clarityProjectId;
Clarity.init(projectId);

function App() {
  return (
    <ErrorBoundary>
      <MsalProvider instance={authInstance.myMSALObj}>
        <Provider store={store}>
          <AuthenticationWrapper>
            <RoutesComponent />
          </AuthenticationWrapper>
        </Provider>
      </MsalProvider>
    </ErrorBoundary>
  );
}

export default App;
